<template>
    <front-layout>
        <v-container>
            <div class="flex-wrapper-full-background d-flex  fill-height" style="width: 100%">
                <v-card class="mx-auto my-auto pa-5" max-width="900" min-width="500" raised>
                    <v-card-title>404</v-card-title>
                    <h1>Page not found</h1>
                    <v-card-text>
                        <router-link :to="{name: 'home'}">Go to the Home page</router-link>
                    </v-card-text>
                </v-card>
            </div>
        </v-container>
    </front-layout>
</template>

<script>

import FrontLayout from "../../layouts/FrontLayout";

export default {
    name: '404',
    components: {FrontLayout},
}
</script>
